import { React } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { OktaConfig } from './_okta/config/OktaConfig';

import { Routes } from './routes/Routes.jsx';
import ScrollToTop from './components/subcomponents/ScrollToTop';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000,
    },
    mutations: {
      retry: 0,
    },
  },
});

export default function App() {
  const history = useHistory();

  const oktaAuth = new OktaAuth({
    clientId: OktaConfig.clientId,
    issuer: OktaConfig.issuer,
    redirectUri: OktaConfig.redirectUri,
    scopes: OktaConfig.scopes,
    pkce: OktaConfig.pkce,
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="portal">
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Router>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </Router>
        </Security>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
